import { forwardRef } from "react";
import {
  carouselButtonVariants,
  carouselContentVariants,
  carouselIndicatorGroupVariants,
  carouselIndicatorVariants,
  carouselItemVariants,
  carouselRootContainerVariants,
  carouselRootVariants,
  type CarouselButtonVariants,
  type CarouselContentVariants,
  type CarouselIndicatorGroupVariants,
  type CarouselIndicatorVariants,
  type CarouselItemVariants,
  type CarouselRootVariants,
} from "./carousel.styles";
import { Icon } from "./icon";

// -----------------------------------------------------------------------------

export type CarouselRootRef = React.ElementRef<"div">;

export interface CarouselRootProps
  extends React.ComponentPropsWithoutRef<"div">,
    CarouselRootVariants {}

const CarouselRoot = forwardRef<CarouselRootRef, CarouselRootProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div {...props} className={carouselRootVariants({ className })}>
        <div className={carouselRootContainerVariants()} ref={ref}>
          {children}
        </div>
      </div>
    );
  },
);

CarouselRoot.displayName = "CarouselRoot";

export { CarouselRoot };

// -----------------------------------------------------------------------------

export type CarouselContentRef = React.ElementRef<"div">;

export interface CarouselContentProps
  extends React.ComponentPropsWithoutRef<"div">,
    CarouselContentVariants {}

const CarouselContent = forwardRef<CarouselContentRef, CarouselContentProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        {...props}
        className={carouselContentVariants({ className })}
        ref={ref}
      />
    );
  },
);

CarouselContent.displayName = "CarouselContent";

export { CarouselContent };

// -----------------------------------------------------------------------------

export type CarouselItemRef = React.ElementRef<"div">;

export interface CarouselItemProps
  extends React.ComponentPropsWithoutRef<"div">,
    CarouselItemVariants {}

const CarouselItem = forwardRef<CarouselItemRef, CarouselItemProps>(
  ({ className, ...props }, ref) => {
    return (
      <div
        {...props}
        className={carouselItemVariants({ className })}
        ref={ref}
      />
    );
  },
);

CarouselItem.displayName = "CarouselItem";

export { CarouselItem };

// -----------------------------------------------------------------------------

export type CarouselButtonPrevRef = React.ElementRef<"button">;

export interface CarouselButtonPrevProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "children" | "type">,
    Omit<CarouselButtonVariants, "placement"> {}

const CarouselButtonPrev = forwardRef<
  CarouselButtonPrevRef,
  CarouselButtonPrevProps
>(({ className, disabled, ...props }, ref) => {
  return (
    <button
      {...props}
      type="button"
      disabled={disabled}
      className={carouselButtonVariants({
        className,
        disabled,
        placement: "left",
      })}
      ref={ref}
    >
      <Icon name="ms-arrow-back" />
      <span className="sr-only">Previous slide</span>
    </button>
  );
});

CarouselButtonPrev.displayName = "CarouselButtonPrev";

export { CarouselButtonPrev };

// -----------------------------------------------------------------------------

export type CarouselButtonNextRef = React.ElementRef<"button">;

export interface CarouselButtonNextProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "children" | "type">,
    Omit<CarouselButtonVariants, "placement"> {}

const CarouselButtonNext = forwardRef<
  CarouselButtonNextRef,
  CarouselButtonNextProps
>(({ className, disabled, ...props }, ref) => {
  return (
    <button
      {...props}
      type="button"
      disabled={disabled}
      className={carouselButtonVariants({
        className,
        disabled,
        placement: "right",
      })}
      ref={ref}
    >
      <Icon name="ms-arrow-forward" />
      <span className="sr-only">Next slide</span>
    </button>
  );
});

CarouselButtonNext.displayName = "CarouselButtonNext";

export { CarouselButtonNext };

// -----------------------------------------------------------------------------

export type CarouselIndicatorGroupRef = React.ElementRef<"div">;

export interface CarouselIndicatorGroupProps
  extends React.ComponentPropsWithoutRef<"div">,
    CarouselIndicatorGroupVariants {}

const CarouselIndicatorGroup = forwardRef<
  CarouselIndicatorGroupRef,
  CarouselIndicatorGroupProps
>(({ className, ...props }, ref) => {
  return (
    <div
      {...props}
      className={carouselIndicatorGroupVariants({ className })}
      ref={ref}
    />
  );
});

CarouselIndicatorGroup.displayName = "CarouselIndicatorGroup";

export { CarouselIndicatorGroup };

// -----------------------------------------------------------------------------

export type CarouselIndicatorRef = React.ElementRef<"button">;

export interface CarouselIndicatorProps
  extends Omit<React.ComponentPropsWithoutRef<"button">, "children" | "type">,
    CarouselIndicatorVariants {
  value: number;
}

const CarouselIndicator = forwardRef<
  CarouselIndicatorRef,
  CarouselIndicatorProps
>(({ className, value, ...props }, ref) => {
  return (
    <button
      {...props}
      type="button"
      className={carouselIndicatorVariants({ className })}
      ref={ref}
    >
      <span className="sr-only">Go to slide {value}</span>
    </button>
  );
});

CarouselIndicator.displayName = "CarouselIndicator";

export { CarouselIndicator };
