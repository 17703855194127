"use client";

import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { forwardRef } from "react";
import { mergeRefs } from "react-merge-refs";
import {
  CarouselButtonNext,
  CarouselButtonPrev,
  CarouselContent,
  CarouselIndicator,
  CarouselIndicatorGroup,
  CarouselItem,
  CarouselRoot,
  type CarouselRootProps,
  type CarouselRootRef,
} from "./carousel.components";
import { useCarouselButtons, useCarouselIndicators } from "./carousel.utils";

export type CarouselRef = CarouselRootRef;

export interface CarouselProps extends CarouselRootProps {
  options?: Parameters<typeof useEmblaCarousel>[0];
  plugins?: Parameters<typeof useEmblaCarousel>[1];
}

const Carousel = forwardRef<CarouselRef, CarouselProps>(
  ({ children, options, plugins, ...props }, ref) => {
    const [rootRef, api] = useEmblaCarousel(options, plugins);

    const { isDisabledPrev, isDisabledNext, handleClickPrev, handleClickNext } =
      useCarouselButtons(api);

    const { selectedIndex, indicatorValues, handleClickIndicator } =
      useCarouselIndicators(api);

    return (
      <CarouselRoot {...props} ref={mergeRefs([rootRef, ref])}>
        <CarouselContent>{children}</CarouselContent>
        <CarouselButtonPrev
          onClick={handleClickPrev}
          disabled={isDisabledPrev}
        />
        <CarouselButtonNext
          onClick={handleClickNext}
          disabled={isDisabledNext}
        />
        <CarouselIndicatorGroup>
          {indicatorValues.map((indicatorValue, idx) => (
            <CarouselIndicator
              value={indicatorValue}
              onClick={() => handleClickIndicator(idx)}
              data-selected={idx === selectedIndex}
              key={indicatorValue}
            />
          ))}
        </CarouselIndicatorGroup>
      </CarouselRoot>
    );
  },
);

Carousel.displayName = "Carousel";

export { Autoplay, Carousel, CarouselItem };
