import { cva, type VariantProps } from "./style.utils";

export type BannerVariants = VariantProps<typeof bannerVariants>;

export const bannerVariants = cva({
  base: [
    // layout
    "grid grid-cols-subgrid col-start-s col-end-e",
    "-mb-30px pb-60px pt-30px",
    "md:-mb-40px md:pb-80px md:pt-40px",
    "xl:-mb-54px xl:pb-108px xl:pt-54px",
    // foreground
    "text-white",
    // background
    "bg-banner bg-[url('/Images/banner-bg.jpg')] bg-cover bg-center bg-no-repeat",
  ],
});
