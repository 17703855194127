import { type EmblaCarouselType } from "embla-carousel";
import { useCallback, useEffect, useState } from "react";

export const useCarouselButtons = (
  api: EmblaCarouselType | undefined,
): {
  isDisabledPrev: boolean;
  isDisabledNext: boolean;
  handleClickPrev: () => void;
  handleClickNext: () => void;
} => {
  const [isDisabledPrev, setIsDisabledPrev] = useState(true);
  const [isDisabledNext, setIsDisabledNext] = useState(true);

  const handleClickPrev = useCallback(() => {
    if (!api) return;
    api.scrollPrev();
  }, [api]);

  const handleClickNext = useCallback(() => {
    if (!api) return;
    api.scrollNext();
  }, [api]);

  const onSelect = useCallback((api: EmblaCarouselType) => {
    setIsDisabledPrev(!api.canScrollPrev());
    setIsDisabledNext(!api.canScrollNext());
  }, []);

  useEffect(() => {
    if (!api) return;
    onSelect(api);
    api.on("reInit", onSelect).on("select", onSelect);
  }, [api, onSelect]);

  return {
    isDisabledPrev,
    isDisabledNext,
    handleClickPrev,
    handleClickNext,
  };
};

export const useCarouselIndicators = (
  api: EmblaCarouselType | undefined,
): {
  selectedIndex: number;
  indicatorValues: number[];
  handleClickIndicator: (index: number) => void;
} => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [indicatorValues, setIndicatorValues] = useState<number[]>([]);

  const handleClickIndicator = useCallback(
    (index: number) => {
      if (!api) return;
      api.scrollTo(index);
    },
    [api],
  );

  const onInit = useCallback((api: EmblaCarouselType) => {
    setIndicatorValues(api.scrollSnapList());
  }, []);

  const onSelect = useCallback((api: EmblaCarouselType) => {
    setSelectedIndex(api.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!api) return;
    onInit(api);
    onSelect(api);
    api.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [api, onInit, onSelect]);

  return {
    selectedIndex,
    indicatorValues,
    handleClickIndicator,
  };
};
