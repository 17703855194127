import { cva, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export type CarouselRootVariants = VariantProps<typeof carouselRootVariants>;

export const carouselRootVariants = cva({
  base: [
    // properties
    "[--slide-spacing:10px]",
    "[--slide-size:100%]",
    // position
    "relative",
  ],
});

export type CarouselRootContainerVariants = VariantProps<
  typeof carouselRootContainerVariants
>;

export const carouselRootContainerVariants = cva({
  base: [
    // layout
    "overflow-hidden",
    // borders
    "rounded-2xl",
  ],
});

// -----------------------------------------------------------------------------

export type CarouselContentVariants = VariantProps<
  typeof carouselContentVariants
>;

export const carouselContentVariants = cva({
  base: [
    // layout
    "flex [backface-visibility:hidden]",
    // layout gap offset
    "ml-[calc(var(--slide-spacing)_*_-1)]",
    // touch
    "touch-pan-y touch-pinch-zoom",
  ],
});

// -----------------------------------------------------------------------------

export type CarouselItemVariants = VariantProps<typeof carouselItemVariants>;

export const carouselItemVariants = cva({
  base: [
    // layout
    "flex items-center justify-center",
    "shrink-0 grow-0 basis-[var(--slide-size)]",
    // layout gap offset
    "pl-[var(--slide-spacing)]",
  ],
});

// -----------------------------------------------------------------------------

export type CarouselButtonVariants = VariantProps<
  typeof carouselButtonVariants
>;

export const carouselButtonVariants = cva({
  base: [
    // layout
    "flex size-36px items-center justify-center",
    // radii/shadow
    "rounded-full shadow-md",
    // border
    "border border-shade-4",
    // background
    "bg-white",
    // position
    "absolute top-1/2 -translate-y-1/2",
  ],
  variants: {
    placement: {
      left: "left-0 -translate-x-1/2",
      right: "right-0 translate-x-1/2",
    },
    disabled: {
      false: "",
      true: "opacity-0",
    },
  },
  defaultVariants: {
    placement: undefined,
    disabled: false,
  },
});

// -----------------------------------------------------------------------------

export type CarouselIndicatorGroupVariants = VariantProps<
  typeof carouselIndicatorGroupVariants
>;

export const carouselIndicatorGroupVariants = cva({
  base: [
    // layout
    "flex gap-4px",
    // position
    "absolute bottom-30px right-30px",
  ],
});

// -----------------------------------------------------------------------------

export type CarouselIndicatorVariants = VariantProps<
  typeof carouselIndicatorVariants
>;

export const carouselIndicatorVariants = cva({
  base: [
    // layout
    "size-10px",
    // radii/shadow
    "rounded-full",
    // border
    "border border-link-idle",
    // selected background
    "data-[selected=true]:bg-link-idle",
  ],
});
